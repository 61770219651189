import React, {useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './ProjectsComponent.css'

import ProjectDetails from '../ProjectDetails/ProjectDetails';

import ProcgenRPG from '../../Projects/ProcgenRPG/ProcgenRPG';
import SiekieraIMieczem from '../../Projects/SiekieraIMieczem/SiekieraIMieczem';
import SixthOfJune from '../../Projects/6thOfJune/6thOfJune';
import GimmeThatMusic from '../../Projects/GimmeThatMusic/GimmeThatMusic';
import QuickRenamer from '../../Projects/QuickRenamer/QuickRenamer';
import RedVoid from '../../Projects/RedVoid/RedVoid';
import Mafia from '../../Projects/Mafia/Mafia';
import Portfolio from '../../Projects/Portfolio/Portfolio';

const Projects = ({currentLanguage}) => {
    const games = [ProcgenRPG, SiekieraIMieczem, SixthOfJune, RedVoid];
    const apps = [GimmeThatMusic, QuickRenamer];
    const web = [Mafia, Portfolio];

    const allProjects = Array.prototype.concat(games, apps, web);

    const getImage = (project,img) => {
        try {
            return require(`../../Projects/${project}/Images/${img}`).default;
        }catch(e)
        {
            console.log(e);
            return "";
        }
    }

    const getLocalizedString = (projectString) => {
        if (projectString === undefined)
            return "Undefined string";

        if (projectString[currentLanguage] === undefined)
            return projectString;
        
        return projectString[currentLanguage];
    }

    const [selectedTab, setSelectedTab] = useState("Games");
    const [currentProjectList, setCurrentProjectList] = useState(games);
    const [selectedProject, setSelectedProject] = useState(games[0]);
    const [currentImage, setCurrentImage] = useState(games[0].images[0].default);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    useEffect(() => {
        setIndex(0);
    }, [currentProjectList]);

    return(
        <div id="projects" className="projects">
            <h1 className="text-center">{currentLanguage==="pl" ? "Projekty" : "Projects"}</h1>
            <ul className="nav flex-column flex-sm-row nav-pills nav-fill w-75 mx-auto  border border-primary rounded-pill p-2">
                <li className="nav-item mx-2" key="games">
                    <a className={`nav-link border border-primary rounded-pill ${(selectedTab==="Games") ? "active" : null}`} href="#games" onClick={(e) => {
                        setSelectedTab("Games");
                        setCurrentProjectList(games);
                    }}>{currentLanguage==="pl" ? "Gry" : "Games"} <i className="fas fa-gamepad"></i></a>
                </li>
                <li className="nav-item mx-2" key="apps">
                    <a className={`nav-link border border-primary rounded-pill ${(selectedTab==="Apps") ? "active" : null}`}  href="#apps" onClick={(e) => {
                        setSelectedTab("Apps");
                        setCurrentProjectList(apps);
                    }}>{currentLanguage==="pl" ? "Aplikacje" : "Apps"} <i className="fas fa-desktop"></i> <i className="fas fa-mobile-alt"></i></a>
                </li>
                <li className="nav-item mx-2" key="web">
                    <a className={`nav-link border border-primary rounded-pill ${(selectedTab==="Web") ? "active" : null}`}  href="#web" onClick={(e) => {
                        setSelectedTab("Web");
                        setCurrentProjectList(web);
                    }}>Web <i className="fas fa-globe"></i></a>
                </li>
            </ul>

            <div className="mt-4">
                <Carousel className="mx-auto" activeIndex={index} interval={null} onSelect={handleSelect} >
                        {
                            currentProjectList.map((x, i) => {
                                return (
                                    <Carousel.Item className="project">
                                    <img src={getImage(x.id,x.coverImage)} alt=" " className="projectCoverFront"></img>
                                    <img src={getImage(x.id,x.coverImage)} alt=" " className="d-block w-100 projectBackground"></img>
                                        <Carousel.Caption className="carousel-caption d-block">
                                            <h5>{getLocalizedString(x.name)}</h5>
                                            <p className="d-none d-md-block">{getLocalizedString(x.desc)}</p>
                                            <button className="btn btn-primary" onClick={() => {
                                                setSelectedProject(x);
                                                if (x.images !== undefined && x.images[0] !== undefined)
                                                    setCurrentImage(x.images[0].default);
                                                else
                                                    setCurrentImage("");
                                            }}> {(currentLanguage === "pl") ? "Więcej" : "Learn more"}</button>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            })
                        }
                </Carousel>
            </div>

            <ProjectDetails selectedProject={selectedProject} getLocalizedString={getLocalizedString} currentLanguage={currentLanguage} />

            
        </div>
    )
}

export default Projects;