import importAll from "../common";


const Mafia = {
    id: "Mafia",
    name: "Mafia",
    coverImage: "2.png",
    desc: {
            gb:"Popular party game 'Mafia' in a browser.",
            pl:"Popularna gra imprezowa 'Mafia' w formie przeglądarkowej"
        },
    year: "2021",
    fulldesc: {
                gb:"Mafia is a popular party game where each player is assigned one of the roles (Mafia, Policeman, Doctor, Citizen). The players do not know the roles of the other participants of the game, the exception is the mafia, which knows other players belonging to this role. The mafia must eliminate the remaining players, while the other players' task is to eliminate the mafia through mutual voting and discussion. The game is turn-based - in the first phase of the night, the mafia selects the person they want to eliminate. In the next phase, the policeman selects a person he suspects of belonging to the mafia, and then the game master informs the policeman whether the designated person belongs to the mafia. In the last phase, the doctor selects a person who will be saved from death in a given turn, if he has appointed the same person as the mafia. The night is followed by a day where players can argue with each other and convince each other who they suspect of being a member of the mafia. After the discussion is over, each player votes for the person they want to eliminate. The person with the most votes is removed from the game and another night follows. <br> <br> Do you have friends you want to play Mafia with, but for some reason you can't meet together? You can play the browser version of the game I created.<br><br>Game is only available in Polish language",
                pl:"Mafia jest popularną grą imprezową, gdzie każdemu z graczy przydzielana jest jedna z ról (Mafia, Policjant, Lekarz, Mieszkaniec). Gracze nie znają ról pozostałych uczestników zabawy, wyjątkiem jest mafia, która zna innych graczy należących do tej roli. Gracze będący mafią muszą wyeliminować pozostałych graczy, zaś zadaniem pozostałych graczy jest eliminacja mafii poprzez wzajemne głosowanie oraz dyskusję. Gra toczy się turowo - w pierwszej fazie nocy mafia wyznacza osobę, którą chcą wyeliminować. W kolejnej fazie policjant wyznacza osobę, którą podejrzewa o przynależność do mafii, po czym mistrz gry informuje policjanta czy wyznaczona osoba należy do mafii. W ostatniej fazie lekarz wyznacza osobę, którą ocali od śmierci w danej turze jeśli wyznaczył tę samą osobę co mafia. Po nocy następuje dzień, gdzie gracze mogą dyskutować między sobą oraz przekonywać się nawzajem kogo podejrzewają o bycie członkiem mafii. Po skończonej dyskusji każdy z graczy głosuje na osobę którą chce wyeliminować. Osoba z największą ilością głosów odpada z gry i następuje kolejna noc.<br><br>Masz znajomych z którymi chcesz zagrać w Mafię, ale z jakiegoś powodu nie możecie się spotkać? Możesz zagrać w przeglądarkową wersję gry, którą stworzyłem."
    },
    techstack: "React,Node,Heroku,JavaScript",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalResources: [{title:"Mafia Online", href:"https://pusiusmafia.herokuapp.com/"}]
}

  

export default Mafia;