import importAll from "../common";


const ProcgenRPG = {
    id: "ProcgenRPG",
    name: {"gb": "Procedurally generated RPG",
            "pl": "Gra RPG z proceduralnie generowaną zawartością"
            },
    coverImage: "procgenrpg.png",
    desc: {
        "gb": "A project created for my engineering thesis 'Development of an RPG with procedurally generated content', nominated for the university contest for the best engineering thesis.",
        "pl": "Projekt będący przedmiotem mojej pracy inżynierskiej pt. 'Opracowanie gry RPG z proceduralnie generowaną zawartością', nominowany do nagrody dziekana za najlepszą pracę inżynierską"
    },
    year: "2020",
    fulldesc: {
        "gb": "This project was the subject of my engineering thesis 'Development an RPG with procedurally generated content.' The main assumption of the project was to create a three-dimensional RPG game in which almost all implemented systems are based upon procedural generation. Thanks to this, the entire game world (including the island, villages, buildings, characters, dialogues and tasks) is generated in real time for each subsequent game. <br> <br> The player task is to escape from island by boarding a ship located in one of the generated villages. To achieve this, the player has to gather ceratin amount of gold coins by completing quests given by villagers, trading or looting. <br> <br> The project received a very good grade with distinction and was nominated for a contest for the best engineering thesis of Faculty of Computer Science of the West Pomeranian University of Technology. <br> <br> Project implementation time: ~ 4 months<br><br>Game is only available in Polish language",
        "pl": "Projekt ten był przedmiotem mojej pracy inżynierskiej pt. 'Opracowanie gry RPG z proceduralnie generowaną zawartością'. Głównym założeniem projektu było utworzenie trójwymiarowej gry komputerowej z gatunku RPG, w której prawie wszystkie zaimplementowane systemy korzystają z mechanik proceduralnej generacji. Dzięki temu, cały świat gry (w tym wyspa, miejscowości, budynki, postacie, dialogi oraz zadania) jest generowany w czasie rzeczywistym dla każdej kolejnej rozgrywki.<br><br>Głównym zadaniem gracza jest jest wydostanie się z wyspy poprzez odpłynięcie statkiem znajdującym się w jednej z wygenerowanych miejscowości. W tym celu gracz musi zdobyć odpowiednią ilość pieniędzy poprzez wykonywanie zadań dla mieszkańców, handel lub grabież.<br><br>Projekt ten otrzymał ocenę bardzo dobrą wraz z wyróżnieniem oraz nominację do nagrody dziekana Wydziału Informatyki Zachodniopomorskiego Uniwersytetu Technologicznego za najlepszą pracę inżynierską.<br><br>Czas realizacji projektu: ~4 miesiące"
    },
    techstack: "Unity,Blender,Bolt,C#",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos: ["https://www.youtube.com/embed/drq_PLDoX30"]
}

  

export default ProcgenRPG;