import importAll from "../common";


const RedVoid = {
    id: "RedVoid",
    name: "Red Void",
    coverImage: "rv1.png",
    desc: {
            gb:"A space survival game created for the IpIT competition of the Maritime University of Szczecin",
            pl:"Gra z gatunku survival stworzona na potrzeby konkursu IpIT Akademii Morskiej w Szczecinie"
        },
    year: "2015",
    fulldesc: {
                gb:"Red Void is a survival game set on a hostile, desert planet. After the crash of a spaceship, the player tries to return to Earth by using the on-board 3D printer to fix damaged parts of the spaceship. The player explores the area in search of materials for the production of parts and resources for survival and discovers the unnerving history of a nearby research study. <br> <br> The project was created for the IpIT competition organized by the Maritime University of Szczecin.<br><br>Game is only available in Polish language",
                pl:"Red Void jest grą z gatunku survival osadzoną na nieprzyjaznej, pustynnej planecie. Po katastrofie swojego statku gracz podejmuje próbę powrotu na Ziemię korzystając z pokładowej drukarki 3D by naprawić uszkodzone elementy statku. Gracz zwiedzając okolicę w poszukiwaniu materiałów do produkcji części oraz zasobów do przetrwania odkrywa mroczną historię pobliskiego ośrodka badawczego.<br><br>Projekt powstał na potrzeby konkursu IpIT organizowanego przez Akademię Morską w Szczecinie"
    },
    techstack: "Unity, C#",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/))
}

  

export default RedVoid;