import importAll from "../common";


const SixthOfJune = {
    id: "6thOfJune",
    name: "6th of June",
    coverImage: "cover.png",
    desc: {
            gb:"A World War II themed third person shooter made for an university course",
            pl:"Trzecioosobowy shooter w klimacie drugiej wojny światowej stworzony w ramach przedmiotu 'Gry komputerowe'"
        },
    year: "2019",
    fulldesc: {
                gb:"6th of June is a World War II themed third-person shooter. The player takes on the role of an American paratrooper taking part in the Allied Invasion of Normandy on June 6, 1944. The player's task is to blow up an anti-aircraft gun that shoots allied planes, and to find the rest of his squad. <br> <br> The game was created as part of the 'Computer games' subject at the West Pomeranian University of Technology. The requirement of the course was to create a computer game using C ++, OpenGL and Qt. This project received a very good grade and was awarded a distinction during the final game review by the lecturers.<br><br>Game only available in Polish language",
                pl:"6th of June jest trzecioosobowym shooterem utrzymanym w klimacie drugiej wojny światowej. Gracz wciela się w amerykańskiego spadochroniarza biorącego udział w alianckiej Inwazji na Normandię w dniu 6 czerwca 1944 roku. Zadaniem gracza jest wysadzić działo przeciwlotnicze ostrzeliwujące sojusznicze samoloty, a następnie odnaleźć resztę swojego oddziału.<br><br>Gra została stworzona w ramach przedmiotu 'Gry komputerowe', prowadzonego na Zachodniopomorskim Uniwersytecie Technologicznym. Wymogiem kursu było utworzenie dowolnej gry komputerowej za pomocą C++, OpenGL oraz Qt. Projekt ten otrzymał ocenę bardzo dobrą, oraz został nagrodzony wyróżnieniem podczas końcowego przeglądu gier przez prowadzących."
    },
    techstack: "Qt,OpenGL,C++",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos:["https://www.youtube.com/embed/mSM_v1iEL9Q"],
    externalResources: [{title:"Github", href:"https://github.com/Pusiu/QtGame"}]
}

  

export default SixthOfJune;