import importAll from "../common";


const Portfolio = {
    id: "Portfolio",
    name: "Portfolio",
    coverImage: "1.png",
    desc: {
            gb:"The website you are viewing right now - my portfolio :)",
            pl:"Strona internetowa którą właśnie przeglądasz - moje portfolio :)"
        },
    year: "2021",
    fulldesc: {
                gb:'Website created to learn React and JavaScript, while also presenting some of my larger projects.',
                pl:"Strona internetowa stworzona w celu nauki React oraz JavaScript, przy okazji prezentując kilka z moich większych projektów."
    },
    techstack: "React, JavaScript",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/))
}

  

export default Portfolio;